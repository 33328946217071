.roles {
  background: rgba(3, 41, 79, 0.15);
  border-radius: 4px;
  width: 55px;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 8px;
  gap: 8px;
}

.headingSlider {
  font-size: 16px;
  font-family: 'Inter';
  color: #03294f;
  font-weight: '500';
  /* line-height: 150%; */
  letter-spacing: 0.1px;
  font-style: normal;
  padding-bottom: 5px;
}

.border {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.slider_header {
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}

.tags {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 88px;
  border-radius: 8px;
  background: rgba(3, 41, 79, 0.8);
  margin-top: 10px;
}
.tags-text {
  color: #f0f7ff;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.4px;
}

.Event-buton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 133px;
  height: 42px;
  left: 250px;
  top: 201px;
  font-size: 14px;
  font-weight: 600;
  color: #0b7de6;
  border-color: transparent;
  background: #ddecff;
  border-radius: 30px;
}
.on {
  background: red;
}
Logout:hover {
  background-color: yellow;
  color: #f0f8ff;
}
