.silder_start1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  align-items: center;
  padding: 13px 16px;
  width: 100%;
  background-color: #FFFFFF;


}

.profile_slider_border {
  background-color: #FFFFFF;
  display: flex;
  justify-content: space-between;
}

.Header_title {
  position: 'absolute';
  text-align: center;
  width: 100%;
  color: #03294FCC;
  /* font-family:'Roboto'; */
  font-style: "normal";
  font-size: 18;
  font-weight: '400';
}

.Event_header {
  /* font-family: "Inter"; */
  font-size: 24px;
  font-weight: 700;
  padding-left: 20px;
  color: #03294F;

}
.ProfileSlider_tags {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 6px;
  width: 80px;
  height: 25px;
  background-color: #FFFFFF;
  border: 1px solid rgba(3, 41, 79, 0.15);
  border-radius: 8px;
}
.my-scrollable-div::-webkit-scrollbar {
  display: none;
}
.my-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.my-scrollbar::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.my-scrollbar::-webkit-scrollbar-thumb {
  background-color: skyblue;
  border-radius: 4px;
}
.form_control{
  width: '88%'; 
  padding-left: 10px; 
  padding-right: 12px;
}
.input{
  height:56;
  color:#03294FCC;
  /* padding-left:30px; */
}