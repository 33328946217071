.main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;


    background-color: #0B7DE6;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
}

.input{
    height: 52px;
    width: 340px;
    border-color: #0B7DE6;
    border-radius: 4px 4px 0px 0px;
    border-color:transparent;
}
.input:focus {
    outline: none;
    border: 1px solid #ccc; /* replace with desired border style */
  }
  