.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #0B7DE6;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App {
  /* display: flex; */
  /* flex: 1; */
  position: fixed;
  justify-content: flex-end;
  background: #F6F6F6;
  z-index: 1;
}


.input_field {
  height: 56px;
  width: 40%;
  left: 1072px;
  top: 113px;
  border-radius: 4px 4px 0px 0px;

}

