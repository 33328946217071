.clock_button{
    /* margin-left:25px; */
    margin-right: 25px;
    margin-bottom:10px;
    margin-top:10px;
    height:25px;
    width:60px;
    border-radius: 5px;
    border-color: transparent;
    background-color: #03294FCC;
    color: white;
}
.Create_event_button {
    display: 'flex';
    flex-direction: 'column';
    border-radius: 100px;
    background-color: #0B7DE6;
    color: white;
    border-color: transparent;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 40px;
}
.Event-Profile {
    height: 20px;
    flex: 1;
    border-bottom: 4px solid #0B7DE6;
}
.Share {
    /* padding-left:100; */
    height: 32px;
    width: 64px;
    /* top: 8px; */
    /* margin-top: 20px; */
    padding: 12px, 14px, 12px, 14px;
    background-color: #DDECFF;
    color: #0B7DE6;
    border-color: transparent;
    border-radius: 30px;
}
.edit {
    width: 25px;
    height: 18px;
    padding-left: 234px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 150%;
    /* padding-top: 9px; */
    color: #03294FCC;
    background-color: transparent;
    border-color: transparent;

}
.silder_start {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 13px 16px;
    width: 100%;
    height: 60px;
    background: #F6F6F6;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);


}